@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #5cb3a7
$_palettes: (
  primary: (
    0: #000000,
    10: #00201c,
    20: #003732,
    25: #00443d,
    30: #005049,
    35: #005d54,
    40: #006a61,
    50: #00867a,
    60: #00a294,
    70: #2bbfaf,
    80: #53dbca,
    90: #73f8e6,
    95: #b4fff3,
    98: #e4fff9,
    99: #f2fffb,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #05201c,
    20: #1c3531,
    25: #27403c,
    30: #324b47,
    35: #3e5753,
    40: #4a635f,
    50: #627c77,
    60: #7c9691,
    70: #96b1ab,
    80: #b1ccc7,
    90: #cce8e2,
    95: #daf7f1,
    98: #e4fff9,
    99: #f2fffb,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #001d31,
    20: #153349,
    25: #223e55,
    30: #2e4961,
    35: #3a556d,
    40: #46617a,
    50: #5e7a93,
    60: #7894ae,
    70: #92aeca,
    80: #adcae6,
    90: #cce5ff,
    95: #e7f2ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #191c1b,
    20: #2d3130,
    25: #383c3b,
    30: #444746,
    35: #505352,
    40: #5c5f5e,
    50: #747876,
    60: #8e9190,
    70: #a9acaa,
    80: #c4c7c5,
    90: #e0e3e1,
    95: #eff1ef,
    98: #f7faf8,
    99: #fafdfb,
    100: #ffffff,
    4: #0b0f0e,
    6: #101413,
    12: #1d201f,
    17: #272b2a,
    22: #323534,
    24: #363a39,
    87: #d8dbd9,
    92: #e6e9e7,
    94: #eceeec,
    96: #f2f4f2,
  ),
  neutral-variant: (
    0: #000000,
    10: #141d1c,
    20: #293230,
    25: #343d3b,
    30: #3f4947,
    35: #4b5452,
    40: #56605e,
    50: #6f7977,
    60: #899390,
    70: #a3adab,
    80: #bec9c6,
    90: #dae5e2,
    95: #e9f3f0,
    98: #f1fcf8,
    99: #f4fffb,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
    typography: (
      plain-family: 'Segoe UI',
      brand-family: 'Segoe UI',
    ),
    density: (
      scale: -2,
    ),
  )
);

@include mat.core();

:root {
  --primary-color: #5cb3a7;
  --primary-lighter-color: #cee8e5;
  --primary-darker-color: #419a8c;

  --accent-color: #081f2c;
  --accent-lighter-color: #b5bcc0;

  --warn-color: #ce0606;
}

html {
  @include mat.all-component-themes($light-theme);
}
