@use './styles/ag-grid';
@use './styles/fonts';
@use './styles/m3-theme';

:root {
  --global-padding: 24px;

  // Increase the contrast of mat cards against background
  --mdc-elevated-card-container-color: #fff;

  // Override sidenav colours
  --mat-sidenav-container-background-color: #fff;
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);

  // Override filled button colour
  --mdc-filled-button-container-color: #5cb3a7;

  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-color: #0000008a;
  --mat-sort-arrow-color: #0000008a;
  --mat-table-row-item-container-height: 42px;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);

  --mat-sidenav-content-background-color: #fafafa;

  --mat-card-title-text-size: 20px;

  --mat-sidenav-container-shape: 0;

  --mat-divider-color: rgba(0, 0, 0, 0.12);

  --mdc-list-list-item-hover-state-layer-color: rgb(205, 205, 205);
  --mdc-list-list-item-hover-state-label-text-color: black;

  --mdc-text-button-container-shape: 5px;
  --mdc-text-button-container-height: 36px;
  --mat-text-button-horizontal-padding: 16px;
  --mdc-filled-button-container-shape: 5px;
  --mdc-filled-button-container-height: 36px;
  --mat-filled-button-horizontal-padding: 16px;
  --mdc-outlined-button-container-shape: 5px;
  --mdc-outlined-button-container-height: 36px;
  --mat-outlined-button-horizontal-padding: 16px;

  --mdc-filled-text-field-container-color: rgba(0, 0, 0, 0.05);

  --mdc-list-list-item-focus-state-layer-color: transparent;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  //--mdc-list-list-item-focus-label-text-color: rgb(92, 179, 167);

  --mdc-switch-selected-pressed-handle-color: white;
  --mdc-switch-selected-hover-handle-color: white;
  --mdc-switch-selected-focus-handle-color: white;

  --mdc-outlined-text-field-disabled-outline-color: transparent;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.15);
  --mdc-outlined-text-field-disabled-label-text-color: #505050;

  --mat-select-disabled-trigger-text-color: rgb(25, 28, 27);

  --mat-expansion-header-text-tracking: normal;
  --mat-expansion-container-text-tracking: normal;
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mat-toolbar-title-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-table-header-headline-tracking: normal;
  --mat-table-footer-supporting-text-tracking: normal;
  --mdc-extended-fab-label-text-tracking: normal;
  --mat-checkbox-label-text-tracking: normal;
  --mat-paginator-container-text-tracking: normal;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mat-menu-item-label-text-tracking: normal;
  --mdc-slider-label-label-text-tracking: normal;
  --mat-radio-label-text-tracking: normal;
  --mdc-dialog-subhead-tracking: normal;
  --mat-select-trigger-text-tracking: normal;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mat-form-field-subscript-text-tracking: normal;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
  --mat-card-title-text-tracking: normal;
  --mat-card-subtitle-text-tracking: normal;
  --mat-optgroup-label-text-tracking: normal;
  --mat-option-label-text-tracking: normal;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-tracking: normal;
  --mdc-list-list-item-label-text-tracking: normal;
  --mat-form-field-container-text-tracking: 1;
  --mdc-icon-button-state-layer-size: 40px;
}

body {
  margin: 0;
}

mat-card {
  // Match the adjusted mat-card background colour
  --mat-table-background-color: #fff;
  --mat-paginator-container-background-color: #fff;
}

.mat-mdc-card-header {
  padding: 16px !important;
}

// Use white text on primary colour backgrounds
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-step-icon {
  color: #fff;
}

snack-bar-container.success {
  background-color: #469b5d;
}

snack-bar-container.error {
  background-color: var(--warn-color);
}

mat-card-content {
  overflow: auto;
}

table {
  width: 100%;

  .hidden {
    display: none;
  }

  .clickable {
    cursor: pointer;
    transition: 50ms ease-out;

    &:hover {
      background-color: #5cb3a706;
    }
  }
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active-chip {
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.active-option {
  &.mat-mdc-list-item {
    --mat-list-active-indicator-shape: 0;
  }
}

.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;

  &.horizontal-padding {
    padding: 5px var(--global-padding);
  }

  .buttons {
    button {
      margin-left: 12px;
    }
  }
}

.mat-mdc-no-data-row {
  td {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.revert-button {
  --mdc-outlined-button-label-text-color: #1d3961;
  --mdc-outlined-button-outline-color: #1d3961;
}

.save-button {
  --mdc-filled-button-container-color: #1d3961;
}

.active-filters-widget {
  margin-left: 12px;
}
