@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-material.css';

.ag-theme-material {
  --ag-material-primary-color: var(--primary-color);
  --ag-material-accent-color: var(--accent-color);
}

// Adds cell borders to editable ag-grids
.editable .ag-cell {
  --b: 1px solid rgba(0, 0, 0, 0.12) !important;

  border-left: var(--b);

  &:last-of-type {
    border-right: var(--b);
  }
}

// Hides selected cell borders from readonly ag-grids
.readonly {
  --ag-row-hover-color: transparent;

  .ag-cell-focus {
    border-color: transparent !important;
  }
}

.ag-theme-material .ag-cell-inline-editing {
  height: 100%;

  input.ag-input-field-input {
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
    padding-left: 15px;
  }
}
